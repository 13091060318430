<template>
  <b-modal v-model="modalState"
           ref="signup-modal"
           @shown="renderGoogleBtn"
           hide-footer>
    <div slot="modal-header"
         class="position-relative d-flex justify-content-center w-100 align-items-center">
      <div class="position-absolute d-flex align-items-center">
        <img src="../../assets/icons/logo.svg" alt="" class="me-3">
        <span class="header-logo">
          MathMaster
        </span>
      </div>
      <button class="close fs-1 d-flex align-items-center justify-content-center ms-auto"
              @click="hideModal"
      >
        &times;
      </button>
    </div>
    <div class="modal-title pb-4">
      <p class="modal-title__text m-0 fs-2 text-primary text-center">
        Get unpuzzled with your math and improve your grades.
      </p>
    </div>
    <p class="fs-2 text-center mb-3">
      Sign Up
    </p>
    <div class="row justify-content-center">
      <div class="col-8">
        <div class="mb-3">
          <label for="email" class="form-label">Email</label>
          <input type="email"
                 ref="emailInput"
                 @input="emailValidation"
                 class="form-control form-control-lg"
                 id="email"
                 aria-describedby="emailInputFeedback"
                 placeholder="example@email.com">
          <div id="emailInputFeedback"
               ref="emailMessageContainer"
               class="invalid-feedback">
            Please enter your email
          </div>
          <a href="https://app.expertchat.me/forgot-password"
             class="d-none"
             ref="resetBtn">
            Reset your Password
          </a>
        </div>
        <div class="d-grid gap-2">
          <button class="btn btn-primary" type="button" @click="register">
            Try Now
          </button>
        </div>
        <p class="modal-divider my-3 text-center position-relative">
          or
        </p>
        <div class="facebook-btn mb-3">
          <div class="d-grid gap-2">
            <button type="button"
                    @click="logInWithFacebook"
                    class="btn btn-outline-primary px-2">
              <img src="../../assets/icons/facebook_logo.svg" alt="FB" class="h-100 me-2 mb-1">
              Continue with Facebook
            </button>
          </div>
        </div>
        <div class="mb-3 d-flex justify-content-center">
          <div id="google-btn-original">
          </div>
        </div>
        <p class="text-center">
          Already an existing user?
          <a href="#" @click="() => {
            this.modalState = false;
            this.$store.commit('openLoginModal');
          }">
            Login Now
          </a>
        </p>
        <p class="text-center modal-terms">
          By continuing you indicate that you've read and agree to our
          <router-link to="terms">
            Terms & Conditions
          </router-link>
          and
          <router-link to="policy">
            Privacy Policy
          </router-link>
        </p>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'SignUpModal',
  computed: {
    modalState: {
      get() {
        return this.$store.state.isSignUpModalShow;
      },
      set() {
        this.$store.commit('closeSignUpModal');
      },
    },
  },
  methods: {
    hideModal() {
      this.modalState = false;
    },
    emailValidation() {
      if (this.$refs.emailInput.value) {
        if (new RegExp('^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')
          .test(this.$refs.emailInput.value)) {
          this.$refs.emailInput.classList.remove('is-invalid');
          this.$refs.emailInput.classList.add('is-valid');
          return true;
        }
        this.$refs.emailInput.classList.remove('is-valid');
        this.$refs.emailInput.classList.add('is-invalid');
        this.$refs.emailMessageContainer.innerText = 'You have entered an invalid email address!';
        return false;
      }
      this.$refs.emailInput.classList.remove('is-valid');
      this.$refs.emailInput.classList.add('is-invalid');
      this.$refs.emailMessageContainer.innerText = 'Email is required!';
      return false;
    },
    async logInWithFacebook() {
      await this.loadFacebookSDK(document, 'script', 'facebook-jssdk', () => {
        this.initFacebook();
        window.FB.login((response) => {
          if (response.authResponse) {
            this.$store.dispatch('oAuthLogin', {
              accessToken: response.authResponse.accessToken,
              serviceName: 'facebook',
            })
              .then((authResp) => {
                this.$store.commit('setUuid', authResp.data.user_uid);
                Promise.all([
                  this.$store.dispatch('clickHouseEvent', {
                    event_type: 'trynow_success',
                    value: 'login_with_facebook',
                  }),
                  this.$store.dispatch('getProfile'),
                ])
                  .then(([, profileResponse]) => {
                    this.$store.commit('setEmail', profileResponse.data.user.email);
                    this.$store.commit('closeSignUpModal');
                    this.$router.push('tutorial');
                  });
              });
          }
        });
      });
      return false;
    },
    async initFacebook() {
      window.fbAsyncInit = () => {
        window.FB.init({
          appId: '478795039958772', // You will need to change this
          cookie: true, // This is important, it's not enabled by default
          version: 'v9.0',
        });
      };
    },
    async loadFacebookSDK(d, s, id, callback) {
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        callback();
        return;
      }
      const js = d.createElement(s);
      js.id = id;
      js.onload = callback;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    },
    renderGoogleBtn() {
      const confObj = {
        type: 'standard',
        theme: 'outline',
        size: 'large',
        text: 'signin_with',
      };
      const btnContainer = document.getElementById('google-btn-original');
      window.google.accounts.id.initialize({
        client_id: '878631912881-j5kfm9hmpv2smpa8dh77dokkt4atnmit.apps.googleusercontent.com',
        callback: (response) => {
          this.$store.dispatch('oAuthLogin', {
            accessToken: response.credential,
            serviceName: 'google',
          })
            .then((authResp) => {
              this.$store.commit('setUuid', authResp.data.user_uid);
              Promise.all([
                this.$store.dispatch('clickHouseEvent', {
                  event_type: 'trynow_success',
                  value: 'login_with_google',
                }),
                this.$store.dispatch('getProfile'),
              ])
                .then(([, profileResponse]) => {
                  this.$store.commit('setEmail', profileResponse.data.user.email);
                  this.$store.commit('closeSignUpModal');
                  this.$router.push('tutorial');
                });
            });
        },
      });
      window.google.accounts.id.renderButton(btnContainer, confObj);
    },
    register() {
      if (this.$refs.emailInput.value.trim()) {
        const formData = new FormData();
        formData.append('email', this.$refs.emailInput.value.trim());
        formData.append('event', this.$store.state.event_id);
        fetch('https://expertchat.mathmaster.me/api/user/register/email', {
          method: 'POST',
          body: formData,
          redirect: 'follow',
        })
          .then((response) => response.json())
          .then((result) => {
            if (result.status === 'success') {
              this.$store.commit('setUuid', result.data.user_uuid);
              this.$store.commit('setEmail', this.$refs.emailInput.value.trim());
              this.$store.commit('closeSignUpModal');
              this.$store.dispatch('clickHouseEvent', {
                event_type: 'store_email',
                value: this.$refs.emailInput.value.trim(),
              });
              this.$router.push('tutorial');
            } else if (result.status === 'error') {
              this.$refs.emailInput.classList.remove('is-valid');
              this.$refs.emailInput.classList.add('is-invalid');
              this.$refs.emailMessageContainer.innerText = result.data.messages;
              this.$refs.resetBtn.classList.replace('d-none', 'd-block');
            }
          })
          .catch((error) => console.log('error', error));
      } else {
        this.emailValidation();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  &-logo {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #141414;
  }
}

.modal {
  &-title {
    background-image: url('../../assets/A+.png');
    background-size: contain;
    background-position: right center;
    background-repeat: no-repeat;

    &__text {
      line-height: 38px;
      letter-spacing: -1.46871px;
    }
  }

  &-divider {
    font-size: 12px;
    letter-spacing: -0.3px;
    color: #767676;

    &:before {
      content: '';
      position: absolute;
      width: 45%;
      background: #D5D5D5;
      height: 1px;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
    }

    &:after {
      content: '';
      position: absolute;
      width: 45%;
      background: #D5D5D5;
      height: 1px;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
    }
  }

  &-terms {
    font-size: 12px;
    line-height: 139%;
    color: #808080;
  }
}
</style>
