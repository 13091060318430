import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import store from '../store/index';

Vue.use(VueRouter);

const routes = [
  {
    path: '/main',
    name: 'Home',
    component: Home,
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import(/* webpackChunkName: "other" */ '../views/Terms.vue'),
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import(/* webpackChunkName: "other" */ '../views/Privacy.vue'),
  },
  {
    path: '/money-back',
    name: 'Money Back',
    component: () => import(/* webpackChunkName: "other" */ '../views/MoneyBack.vue'),
  },
  {
    path: '/sub-terms',
    name: 'Subscription terms',
    component: () => import(/* webpackChunkName: "other" */ '../views/SubTerms.vue'),
  },
  {
    path: '/tutorial',
    name: 'Tutorial',
    component: () => import(/* webpackChunkName: "proc" */ '../views/Tutorial.vue'),
  },
  {
    path: '/success',
    name: 'Success',
    component: () => import(/* webpackChunkName: "proc" */ '../views/Success.vue'),
  },
  {
    path: '/special-offer',
    name: 'Special Plan',
    component: () => import(/* webpackChunkName: "plans" */ '../views/Plans_special.vue'),
  },
  {
    path: '/plans',
    name: 'Plans',
    component: () => import(/* webpackChunkName: "proc" */ '../views/Plans_v2.vue'),
  },
  {
    path: '*',
    redirect: '/main',
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if ((to.name === 'Plans' || to.name === 'Special Plan' || to.name === 'Success') && !store.state.uuid) {
    next({ name: 'Home' });
  } else {
    next();
  }
});

export default router;
