import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    uuid: '' || window.localStorage.getItem('user_uuid'),
    appLink: '' || window.localStorage.getItem('appLink'),
    email: '' || window.localStorage.getItem('email'),
    isSignUpModalShow: false,
    isLoginModalShow: false,
    credentials: {},
    event_id: document.querySelector('#eventId')
      .innerText
      .trim()
      .includes('{{')
      ? '35391962'
      : document.querySelector('#eventId')
        .innerText
        .trim(),
    landing_uuid: document.querySelector('#landing_uuid')
      .innerText
      .trim()
      .includes('{{')
      ? 'd7aab0d3-db92-4845-a9c0-7546827d60bc'
      : document.querySelector('#landing_uuid')
        .innerText
        .trim(),
    cloaking: (() => {
      const cloak = document.querySelector('#cloaking')
        .innerHTML
        .trim();
      document.querySelector('#cloaking')
        .remove();
      return cloak.includes('if') ? 'true' : cloak;
    })(),
  },
  getters: {
    getAppLink() {
      return window.localStorage.getItem('appLink');
    },
  },
  mutations: {
    setUuid(state, value) {
      if (value) {
        state.uuid = value;
        window.localStorage.setItem('user_uuid', value);
      }
    },
    setCredentials(state, value) {
      state.credentials = value;
    },
    setEmail(state, value) {
      state.email = value;
      window.localStorage.setItem('email', value);
    },
    closeSignUpModal(state) {
      state.isSignUpModalShow = false;
    },
    closeLoginModal(state) {
      state.isLoginModalShow = false;
    },
    openLoginModal(state) {
      state.isLoginModalShow = true;
    },
    openSignUpModal(state) {
      state.isSignUpModalShow = true;
    },
  },
  actions: {
    clickHouseEvent(context, eventObj) {
      const formData = new FormData();
      formData.append('event_id', context.state.event_id);
      formData.append('event_type', eventObj.event_type);
      if (eventObj.value) {
        formData.append('value', eventObj.value);
      }
      return fetch('https://event.fx2.io/store',
        {
          method: 'POST',
          body: formData,
        });
    },
    getProfile(context) {
      const myHeaders = new Headers();
      myHeaders.append('Authorization', context.state.uuid);
      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
      };
      return fetch('https://expertchat.mathmaster.me/api/user/profile/show', requestOptions)
        .then((response) => response.json())
        .catch((error) => error);
    },
    getProducts(context, exceptProduct) {
      const formData = new FormData();
      formData.append('event_id', context.state.event_id);
      formData.append('except_subgroup', exceptProduct);
      return fetch('https://web2app.fx2.io/payment/form/products',
        {
          method: 'POST',
          body: formData,
        })
        .then((resp) => resp.json())
        .then((resp) => resp.data);
    },
    getCredentials(context, {
      login,
      password,
    }) {
      const formData = new FormData();
      formData.append('email', login);
      formData.append('password', password);
      return fetch('https://expertchat.mathmaster.me/api/user/auth', {
        method: 'POST',
        body: formData,
      })
        .then((data) => data.json());
    },
    checkTransactionStatus(context, orderUuid) {
      const formData = new FormData();
      formData.append('order_uuid', orderUuid);
      return fetch('https://web2app.fx2.io/order/status', {
        method: 'POST',
        body: formData,
      })
        .then((data) => data.json());
    },
    oAuthLogin(context, {
      accessToken,
      serviceName,
    }) {
      const formData = new FormData();
      formData.append('access_token', accessToken);
      formData.append('service_name', serviceName);
      const requestOptions = {
        method: 'POST',
        body: formData,
        redirect: 'follow',
      };
      return fetch('https://expertchat.mathmaster.me/api/user/oauth', requestOptions)
        .then((response) => response.json())
        .catch((error) => error);
    },
  },
  modules: {},
});
