import Vue from 'vue';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import { BModal, BSpinner } from 'bootstrap-vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'swiper/swiper.min.css';
import './main.scss';

Vue.config.productionTip = false;
Vue.use(VueAwesomeSwiper /* { default options with global component } */);

Vue.component('b-modal', BModal);
Vue.component('b-spinner', BSpinner);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
document.title = 'MathMaster';

export function setCookie(name, value, options = {}) {
  const newOptions = {
    path: '/',
    ...options,
  };
  if (newOptions.expires instanceof Date) {
    newOptions.expires = newOptions.expires.toUTCString();
  }
  let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const optionKey in newOptions) {
    updatedCookie += `; ${optionKey}`;
    const optionValue = newOptions[optionKey];
    if (optionValue !== true) {
      updatedCookie += `=${optionValue}`;
    }
  }

  document.cookie = updatedCookie;
}

export function getCookie(name) {
  const regex = new RegExp('([\\.$?*|{}\\(\\)\\[\\]\\\\\\/\\+^])', 'g');
  const matches = document.cookie.match(new RegExp(
    `(?:^|; )${name.replace(regex, '\\$1')}=([^;]*)`,
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function deleteCookie(name) {
  setCookie(name, '', {
    'max-age': -1,
  });
}
