<template>
  <b-modal v-model="modalState"
           @shown="renderGoogleBtn"
           hide-footer>
    <div slot="modal-header"
         class="position-relative d-flex justify-content-center w-100 align-items-center">
      <div class="position-absolute d-flex align-items-center">
        <img src="../../assets/icons/logo.svg" alt="" class="me-3">
        <span class="header-logo">
          MathMaster
        </span>
      </div>
      <button class="close fs-1 d-flex align-items-center justify-content-center ms-auto"
              @click="hideModal"
      >
        &times;
      </button>
    </div>
    <p class="fs-2 text-center mb-3">
      Login
    </p>
    <div class="row justify-content-center">
      <div class="col-10">
        <div class="mb-3">
          <label class="form-label">Email</label>
          <input type="email"
                 ref="emailInput"
                 id="loginInput"
                 class="form-control form-control-lg"
                 @change="emailValidation"
                 aria-describedby="loginInputFeedback"
                 placeholder="example@email.com">
          <div id="loginInputFeedback" class="invalid-feedback">
            Please enter your email
          </div>
        </div>
        <div class="mb-3">
          <label class="form-label">Password</label>
          <input type="password"
                 ref="passwordInput"
                 id="loginPasswordInput"
                 class="form-control form-control-lg"
                 @input="passwordValidation"
                 aria-describedby="loginPasswordInputFeedback"
                 placeholder="password...">
          <div ref="passwordInputFeedback"
               id="loginPasswordInputFeedback"
               class="invalid-feedback">
            Please enter some somethings
          </div>
          <p ref="resetText" class="d-none">
            Forgot your password?
            <a href="https://app.expertchat.me/forgot-password" ref="resetBtn">
              Reset your Password
            </a>
          </p>
        </div>
        <div class="d-grid gap-2">
          <button class="btn btn-primary"
                  type="button"
                  @click="login"
          >
            Login
          </button>
        </div>
        <p class="modal-divider my-3 text-center position-relative">
          or
        </p>
        <div class="facebook-btn mb-3">
          <div class="d-grid gap-2">
            <button type="button"
                    @click="logInWithFacebook"
                    class="btn btn-outline-primary px-2">
              <img src="../../assets/icons/facebook_logo.svg" alt="FB" class="h-100 me-2 mb-1">
              Continue with Facebook
            </button>
          </div>
        </div>
        <div class="mb-3 d-flex justify-content-center">
          <div id="google-btn-original">
          </div>
        </div>
        <p class="text-center">
          New user?
          <a href="#"
             @click="() => {
               this.modalState = false;
               this.$store.commit('openSignUpModal');
             }"
          >
            Sign up
          </a>
        </p>
        <p class="text-center modal-terms">
          By continuing you indicate that you've read and agree to our
          <router-link to="terms">
            Terms & Conditions
          </router-link>
          and
          <router-link to="policy">
            Privacy Policy
          </router-link>
        </p>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'LoginModal',
  computed: {
    modalState: {
      get() {
        return this.$store.state.isLoginModalShow;
      },
      set() {
        this.$store.commit('closeLoginModal');
      },
    },
  },
  methods: {
    hideModal() {
      this.modalState = false;
    },
    emailValidation() {
      if (!this.$refs.emailInput.value) {
        this.$refs.emailInput.classList.add('is-invalid');
        return false;
      }
      this.$refs.emailInput.classList.replace('is-invalid', 'is-valid');
      return true;
    },
    passwordValidation() {
      if (!this.$refs.passwordInput.value) {
        this.$refs.passwordInput.classList.add('is-invalid');
        return false;
      }
      this.$refs.passwordInput.classList.replace('is-invalid', 'is-valid');
      return true;
    },
    login() {
      if (this.$refs.passwordInput.value && this.$refs.emailInput.value) {
        const formData = new FormData();
        formData.append('email', this.$refs.emailInput.value);
        formData.append('password', this.$refs.passwordInput.value);
        fetch('https://expertchat.mathmaster.me/api/user/auth', {
          method: 'POST',
          body: formData,
          redirect: 'follow',
        })
          .then((response) => response.json())
          .then((result) => {
            if (result.status === 'success') {
              this.$store.commit('setUuid', result.data.user_uid);
              window.localStorage.setItem('appLink', `https://app.expertchat.me/?auth=${result.data.user_uid}`);
              this.$store.dispatch('clickHouseEvent', {
                event_type: 'login_modal_success',
                value: 'Email',
              })
                .then(() => {
                  window.location.href = window.localStorage.getItem('appLink');
                });
            } else {
              this.$refs.passwordInput.classList.remove('is-valid');
              this.$refs.passwordInput.classList.add('is-invalid');
              this.$refs.resetText.classList.remove('d-none');
              this.$refs.passwordInputFeedback.innerText = result.data.messages;
            }
          })
          .catch((error) => console.log('error', error));
      } else {
        this.passwordValidation();
        this.emailValidation();
      }
    },
    async logInWithFacebook() {
      await this.loadFacebookSDK(document, 'script', 'facebook-jssdk', () => {
        this.initFacebook();
        window.FB.login((response) => {
          if (response.authResponse) {
            this.$store.dispatch('oAuthLogin', {
              accessToken: response.authResponse.accessToken,
              serviceName: 'facebook',
            })
              .then((authResp) => {
                window.localStorage.setItem('appLink', `https://app.expertchat.me/?auth=${authResp.data.user_uid}`);
                return this.$store.dispatch('clickHouseEvent', {
                  event_type: 'login_modal_success',
                  value: 'login_with_google',
                });
              })
              .then(() => {
                window.open(localStorage.getItem('appLink'), '_self');
              });
          }
        });
      });
      return false;
    },
    async initFacebook() {
      window.fbAsyncInit = () => {
        window.FB.init({
          appId: '478795039958772', // You will need to change this
          cookie: true, // This is important, it's not enabled by default
          version: 'v9.0',
        });
      };
    },
    async loadFacebookSDK(d, s, id, callback) {
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        callback();
        return;
      }
      const js = d.createElement(s);
      js.id = id;
      js.onload = callback;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    },
    renderGoogleBtn() {
      const confObj = {
        type: 'standard',
        theme: 'outline',
        size: 'large',
        text: 'signup_with',
      };
      const btnContainer = document.getElementById('google-btn-original');
      window.google.accounts.id.initialize({
        client_id: '878631912881-j5kfm9hmpv2smpa8dh77dokkt4atnmit.apps.googleusercontent.com',
        callback: (response) => {
          this.$store.dispatch('oAuthLogin', {
            accessToken: response.credential,
            serviceName: 'google',
          })
            .then((authResp) => {
              window.localStorage.setItem('appLink', `https://app.expertchat.me/?auth=${authResp.data.user_uid}`);
              return this.$store.dispatch('clickHouseEvent', {
                event_type: 'login_modal_success',
                value: 'login_with_facebook',
              });
            })
            .then(() => {
              window.open(localStorage.getItem('appLink'), '_self');
            });
        },
      });
      window.google.accounts.id.renderButton(btnContainer, confObj);
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  &-logo {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #141414;
  }
}

.modal {
  &-title {
    background-image: url('../../assets/A+.png');
    background-size: contain;
    background-position: right center;
    background-repeat: no-repeat;

    &__text {
      line-height: 38px;
      letter-spacing: -1.46871px;
    }
  }

  &-divider {
    font-size: 12px;
    letter-spacing: -0.3px;
    color: #767676;

    &:before {
      content: '';
      position: absolute;
      width: 45%;
      background: #D5D5D5;
      height: 1px;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
    }

    &:after {
      content: '';
      position: absolute;
      width: 45%;
      background: #D5D5D5;
      height: 1px;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
    }
  }

  &-terms {
    font-size: 12px;
    line-height: 139%;
    color: #808080;
  }
}
</style>
