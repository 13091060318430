<template>
  <div class="footer bg-primary">
    <div class="container py-4">
      <div class="d-flex align-items-center justify-content-between">
        <p class="text-white m-0">
          © 2021 Starnest Services Ltd, Limassol, Cyprus. All rights reserved.
        </p>
        <div class="d-flex">
          <router-link to="privacy" class="btn btn-link text-white text-decoration-none p-0">
            Privacy Policy
          </router-link>
          <router-link to="terms" class="btn btn-link ms-4 text-white text-decoration-none p-0">
            Terms and conditions
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style scoped>

</style>
