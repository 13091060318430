<template>
  <div id="app">
    <Header v-if="mainPage"/>
    <router-view/>
    <Footer v-if="mainPage"/>
    <SignUpModal/>
    <LoginModal/>
  </div>
</template>

<script>
import Header from '@/components/common/Header.vue';
import Footer from '@/components/common/Footer.vue';
import SignUpModal from '@/components/common/SignUpModal.vue';
import LoginModal from '@/components/common/LoginModal.vue';
// eslint-disable-next-line import/no-cycle
import { getCookie, setCookie } from '@/main';

export default {
  components: {
    Header,
    Footer,
    SignUpModal,
    LoginModal,
  },
  data() {
    return {
      mainPage: true,
    };
  },
  methods: {
    isMainPage() {
      return /(^\/main)|(\/privacy)|(\/terms)/.test(this.$router.currentRoute.path);
    },
  },
  beforeMount() {
    if (!getCookie('planchik')) {
      this.$store.dispatch('getProducts')
        .then((data) => {
          setCookie('planchik', JSON.stringify(data.products));
        });
    }
  },
  created() {
    this.mainPage = this.isMainPage();
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.mainPage = this.isMainPage();
      },
    },
  },
};
</script>

<style>
#app {
  height: 100%;
}
</style>
