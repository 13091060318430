<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-2 d-flex align-items-center justify-content-center">
        <span class="slider-control" @click="prevSlide">
          <img src="../../assets/arrow_left.svg" alt="">
        </span>
      </div>
      <div class="col-5 align-items-stretch">
        <Swiper ref="mySwiper" :options="swiperOption">
          <SwiperSlide v-for="comment in comments" :key="comment.id">
            <div class="slide">
              <div class="slide-content d-flex flex-column align-items-center">
                <img :src="comment.avatarUrl" class="slide__photo rounded-circle mb-5" alt=""/>
                <p class="slide__text text-secondary text-center mb-5">
                  {{ comment.text }}
                </p>
                <p class="slid__name fs-5 mb-1">
                  {{ comment.name }}
                </p>
                <p class="slide__position text-secondary mb-0">
                  {{ comment.position }}
                </p>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div class="col-2 d-flex align-items-center justify-content-center">
        <span class="slider-control" @click="nextSlide">
          <img src="../../assets/arrow_right.svg" alt="">
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

export default {
  name: 'Slider',
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    comments: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      offsetLeft: 0,
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 24,
        centeredSlides: true,
      },
      activeSlide: 0,
    };
  },
  methods: {
    nextSlide() {
      // if (this.offsetLeft === 296 * (this.comments.length - 1)) {
      //   this.offsetLeft = 0;
      //   this.$refs.sliderContent.style.left = `-${this.offsetLeft}px`;
      // } else {
      //   this.offsetLeft += 296;
      //   this.$refs.sliderContent.style.left = `-${this.offsetLeft.toString()}px`;
      // }
      if (this.activeSlide < this.comments.length - 1) {
        this.activeSlide += 1;
        this.swiper.slideTo(this.activeSlide, 500, false);
      } else {
        this.activeSlide = 0;
        this.swiper.slideTo(this.activeSlide, 500, false);
      }
    },
    prevSlide() {
      if (this.activeSlide > 0) {
        this.activeSlide -= 1;
        this.swiper.slideTo(this.activeSlide, 500, false);
      } else {
        this.activeSlide = this.comments.length - 1;
        this.swiper.slideTo(this.activeSlide, 500, false);
      }
    },
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
};
</script>

<style scoped lang="scss">
.swiper-container {
  overflow: hidden;
}

.slide {
  &__photo {
    width: 68px;
    height: 68px;
  }
}
</style>
