<template>
  <div class="home">
    <div class="hero bg-light pt-5">
      <div class="container">
        <div class="row">
          <div class="col offset-1">
            <h1 class="title-part mb-32">
              Your private tutor, available 24/7
            </h1>
          </div>
        </div>
        <div class="row">
          <div class="col-7 offset-1">
            <ul class="hero-list ps-0">
              <li class="hero-list__item d-flex align-items-center mb-4">
                <img src="../assets/check_in_circle.svg" alt="">
                <span class="mx-3 f-b-s">
                  Verified tutors only
                </span>
              </li>
              <li class="hero-list__item d-flex align-items-center mb-4">
                <img src="../assets/check_in_circle.svg" alt="">
                <span class="mx-3 f-b-s">
                  Take a photo of your problem or just type it in our chat
                </span>
                <span class="hero-list__new"></span>
              </li>
              <li class="hero-list__item d-flex align-items-center mb-32">
                <img src="../assets/check_in_circle.svg" alt="">
                <span class="mx-3 f-b-s">
                  Get a straightforward answer or a step-by-step solution
                </span>
              </li>
            </ul>
            <div class="d-grid gap2 col-5">
              <button class="btn btn-primary rounded-pill btn-block" type="button"
                      @click="tryNowBtnClick($event)"
              >
                Try Now
              </button>
            </div>
          </div>
          <div class="col-4">
            <img src="../assets/woman.png" alt="" class="hero__illustration">
          </div>
        </div>
      </div>
    </div>
    <div class="mockups bg-light pt-5">
      <div class="mockups-bg">
        <div class="container">
          <div class="row">
            <div class="col-6 offset-1">
              <h2 class="title-part mb-32">
                Send us a problem.
                Get&nbsp;a&nbsp;solution. Learn!
              </h2>
              <div class="d-grid gap2 col-6">
                <button class="btn btn-primary rounded-pill btn-block" type="button"
                        @click="tryNowBtnClick($event)"
                >
                  Try Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="teach bg-white pt-5 overflow-hidden">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-6">
            <h2 class="title-part mb-32">
              Our areas of expertise:
            </h2>
            <div class="row justify-content-center">
              <div class="col-9">
                <p class="mb-72 text-secondary text-center">
                  MathMaster’s Experts specialise in a wide range of areas.
                  Never feel outnumbered with your math again.
                </p>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <img src="../assets/teacher.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="help bg-primary py-5">
      <div class="container">
        <div class="row">
          <div class="col-6 offset-1">
            <h2 class="text-white mb-32">
              How would you like us to help you?
            </h2>
            <p class="m-0 text-white opacity-75">
              Whether you’re stuck with algebra or puzzled by a trigonometry question, our Expert’s
              are here to help.
            </p>
          </div>
          <div class="col-5 d-flex align-items-center justify-content-center">
            <div class="d-grid gap2 col-7">
              <button class="btn btn-outline-light rounded-pill btn-block" type="button"
                      @click="tryNowBtnClick($event)"
              >
                Get Help
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="features bg-danger">
      <div class="features-bg py-90">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-2 offset-1">
              <div class="features-block d-flex justify-content-center align-items-end">
                <p class="features__rate me-4">
                  1.6mln
                </p>
                <p class="features__text text-start m-0">
                  Solved Tasks
                </p>
              </div>
            </div>
            <div class="col-2 offset-1">
              <div class="features-block d-flex justify-content-center align-items-end">
                <p class="features__rate me-4">
                  80+
                </p>
                <p class="features__text text-start m-0">
                  Pro Experts
                </p>
              </div>
            </div>
            <div class="col-2 offset-1">
              <div class="features-block d-flex justify-content-center align-items-end">
                <p class="features__rate me-4">
                  A+
                </p>
                <p class="features__text text-start m-0">
                  Knowledge Level
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="comments bg-white py-5">
      <slider :comments="comments" class="works-slider"/>
    </div>
  </div>
</template>

<script>
import Slider from '../components/common/Slider.vue';

export default {
  name: 'Home',
  components: {
    Slider,
  },
  data() {
    return {
      comments: [
        {
          id: 0,
          name: 'Mark  & Laura',
          position: 'Student',
          avatarUrl: 'https://picsum.photos/68/68',
          text: 'Wish I had found this service earlier. I am studying in college and working at the same time. I have little time for homework and MathMaster helps me everytime. The Experts are quick to respond and provide step-by-step answers.',
        },
        {
          id: 1,
          name: 'Megan',
          position: 'Student',
          avatarUrl: 'https://picsum.photos/78/78',
          text: 'Before MathMaster, I always felt lost when doing my math homework. The step-by-step solutions helped me learn how to solve problems and I\'m one of the top students in class.',
        },
        {
          id: 2,
          name: 'Alexa',
          position: 'Student',
          avatarUrl: 'https://picsum.photos/88/88',
          text: '“I use this app mostly to check my work and see exactly where I went wrong in solving. I use this on all of my homework and practice notes. Five out five stars, hands down.”',
        },
      ],
    };
  },
  methods: {
    tryNowBtnClick(event) {
      if (this.$store.getters.getAppLink || this.$store.state.uuid) {
        if (this.$store.getters.getAppLink) {
          window.open(this.$store.getters.getAppLink, '_self');
        } else if (this.$store.state.uuid) {
          this.$router.push('tutorial');
        }
      } else {
        this.$store.commit('openSignUpModal');
      }
      this.$store.dispatch('clickHouseEvent', {
        event_type: 'landing_firstpage_click',
        value: event.target.innerText.toLowerCase(),
      });
    },
  },
  mounted() {
    this.$store.dispatch('clickHouseEvent', {
      event_type: 'landing_firstpage_view',
    });
  },
};
</script>

<style lang="scss" scoped>
.hero {
  overflow: hidden;

  &__illustration {
    max-height: 421px;
  }
}

.mockups {
  background: #DFE0F8;
  height: 1000px;

  &-bg {
    background-image: url('../assets/bg_mockups.png');
    height: 100%;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: contain;
  }
}

.features {
  &-bg {
    background-image: url('../assets/features.png');
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &__rate {
    font-weight: bold;
    font-size: 64px;
    line-height: 72px;
    padding-bottom: 16px;
    position: relative;
    margin: 0;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 8px;
      width: 100%;
      background: #000;
    }
  }
}

.mb-72 {
  margin-bottom: 72px;
}

.py-90 {
  padding-top: 90px;
  padding-bottom: 90px;
}
</style>
