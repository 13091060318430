<template>
  <div class="header bg-light">
    <div class="container">
      <div class="header-container d-flex">
        <div class="header__logo d-flex align-items-center">
          <img src="../../assets/icons/logo.svg" alt="" class="header__logo-img logo-svg">
          <span class="header__logo-text">
            MathMaster
          </span>
        </div>
        <div class="header-nav d-flex align-items-center ms-5">
          <router-link to="money-back" class="header-nav__link mx-3">
            Money-Back
          </router-link>
          <router-link to="privacy" class="header-nav__link mx-3">
            Privacy
          </router-link>
          <router-link to="terms" class="header-nav__link mx-3">
            Terms
          </router-link>
          <router-link
            :to="{ path: '/tutorial', query: { faq: true }}"
            class="header-nav__link mx-3">
            FAQ
          </router-link>
          <a href="mailto:support@mathmaster.me" class="header-nav__link mx-3">
            Support
          </a>
        </div>
        <div class="header-actions ms-auto">
          <button class="btn btn-primary rounded-pill px-4" type="button"
                  @click="tryNowBtnClick"
          >
            Try Now
          </button>
          <button class="btn btn-outline-primary rounded-pill border-2 px-4 ms-3" type="button"
                  v-if="!this.$store.state.uuid"
                  @click="loginBtnClick"
          >
            Login
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  methods: {
    tryNowBtnClick() {
      this.$store.dispatch('clickHouseEvent', {
        event_type: 'landing_firstpage_click',
        value: 'try now',
      })
        .then(() => {
          if (this.$store.state.appLink || this.$store.state.uuid) {
            if (this.$store.state.appLink) {
              window.open(this.$store.state.appLink, '_self');
            } else if (this.$store.state.uuid) {
              this.$router.push('tutorial');
            }
          } else {
            this.$store.commit('openSignUpModal');
          }
        });
    },
    loginBtnClick() {
      this.$store.commit('openLoginModal');
      this.$store.dispatch('clickHouseEvent', {
        event_type: 'landing_firstpage_click',
        value: 'login',
      });
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  padding: 8px;

  &__logo {
    &-img {
      margin-right: 11px;
    }

    &-text {
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
    }
  }

  &-nav {
    &__link {
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: -0.355556px;
      text-decoration: none;
      color: #141414;
    }
  }
}
</style>
